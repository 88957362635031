<template>
<section id="Banner-Section" class="Banner-Section mt-5">
    <div class="container">
  
      <div class="text-center mt-5" data-aos="zoom-in">
        <h1>{{ title }}</h1>
        <h4>(National College Of Chest Physician)</h4>
       
      </div>
  
    </div>
  </section> 

</template>
<script>
import BannerSection from "@/components/BannerSection.vue";

export default{
    name: 'BannerSection',
    component:{
        BannerSection,
    },
    props: {
      title: String
    }
    }

</script>     
<style>
.Banner-Section {
    background: linear-gradient(rgba(2, 2, 2, 0.7), rgba(0, 0, 0, 0.7)), url("../assets/banner-bg.jpg") fixed center center;
    background-size: cover;
    height: 410px;
    position: relative;
    top: -50px;
    display: grid;
    place-items: center;

  }
  
  .Banner-Section h1 {
    color: #fff;
    font-weight: 600;

  }
  
  .Banner-Section h4 {
    color: #fff;
  }
  
  .Banner-Section .about-btn {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 8px 28px;
    border-radius: 25px;
    transition: 0.5s;
    margin-top: 10px;
    border: 2px solid #fff;
    color: #fff;
  }
  
  .Banner-Section .about-btn:hover {
    background: #3b71ca;
    border: 2px solid #3b71ca;
  }</style>