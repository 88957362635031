<template>
  <BannerSection title="Parke-Davis NCCP (I) Chest Oration" />
  <div class="container">
    <h3>Parke-Davis NCCP (I) Chest Oration</h3><hr>
    <p>The Association / College has awards to encourage research activities in the field of T.B. and Respiratory Diseases as well as their contribution to the specialty.</p>

    <div class="section-award">
      <h4>(A) WARNER NCCP (I) ORATION (renamed as PARKE-DAVIS NCCP (I) CHEST ORATION in 1992</h4>
      <p>The Warner Hindustan Ltd. through the Asthma, Bronchitis & Cancer Lung Foundation of India, funded annually an oration on any aspect of Chest Diseases, to be delivered at the time of the annual conference.</p>
    <p>On the special request of the donors, the 151 oration was delivered in 1972 by Dr. Vishwanathan, who had perhaps the longest experience in the field of chest diseases. The name of the oration was changed to Parke-Davis / NCCP (I) Chest Oration since 1992, on the request of the organization since Warner Hindustan Company merged in Parke-Davis Company. The award was finally discontinued after 1995. The list of orators is given below.</p>
    </div>


    <table>
      <tr>
        <th
          v-for="(heading, index) in tableHeading"
          :key="index"
          class="tableHeading"
        >
          {{ heading }}
        </th>
      </tr>

      <tr v-for="(data, index) in tableData" :key="index">
        <td>{{ data.years }}</td>
        <td>{{ data.names }}</td>
      </tr>
    </table>

  </div>



</template>


<script>
import BannerSection from '@/components/BannerSection.vue';
export default {
  components: {
    BannerSection,
  }
}
</script>


<style scoped>
.section-award{
  background: rgb(241, 241, 241);
  padding: 20px 30px;
}

.section-award h4{
  color: #0d6efd;
}

@media(max-width: 768px){
  .section-award{
    background: rgb(241, 241, 241);
    padding: 0px 10px;
  }
}
</style>  