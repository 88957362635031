<template>
    <div style="margin-top: 200px; display: grid; place-items: center">
      <form @submit.prevent="napcondatas">
        <h3 class="text-center display-7">Executive Member List</h3><br>
        <div>
          <label for="years">Years:</label><br>
          <input type="number" v-model="napconmembers.year" id="years" required>
        </div>
        <div>
          <label for="place">Place: </label><br>
          <input type="text" v-model="napconmembers.place" id="place" required>
        </div>
        <div>
          <label for="secretary">Organising Secretary:</label><br>
          <input type="text" v-model="napconmembers.secretary" id="secretary">
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        napconmembers: {
          secretary: '',
          year: '',
          place: '',
        },
      };
    },
    methods: {
      async napcondatas() {
        try {
          await axios.post('http://localhost:3000/Napcondata/PostNAPCONmembers', this.napconmembers);
          this.$emit('formSubmitted');
          alert("Successfully Submitted");
        } catch (error) {
          console.error('Error submitting form:', error);
        }
      }
    }
  };
  </script>
  
  <style scoped>
  form {
    width: 25%;
    padding: 20px;
  }
  
  input {
    width: 100% !important;
    border: none;
    border-bottom: 1px solid gray;
    outline: none;
  }
  
  button {
    width: 100%;
    border: none;
    outline: none;
    background-color: #0d6efd;
    padding: 5px 20px;
    color: #fff;
    font-weight: 600;
  }
  
  div {
    margin-bottom: 15px;
  }
  </style>
  