    <template>
    <BannerSection title="Our Guidelines" />
    <div class="container">
        <h3>Administration Guidelines for NCCP India (National College of Chest Physician)</h3><hr>
    </div>
    </template>

    <script>
    import BannerSection from "@/components/BannerSection.vue";

    export default {
    components: { 
        BannerSection,
    },
    };
    </script>
