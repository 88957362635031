
import 'bootstrap/dist/css/bootstrap.css';
import { createApp } from 'vue';
import App from './App.vue';
import router from './routes';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'aos/dist/aos.css';
import 'bootstrap/dist/js/bootstrap.js';
import CanvasJSChart from '@canvasjs/vue-charts';
import Toast from "vue3-toastify";
import "vue3-toastify/dist/index.css";

const app = createApp(App)
app.use(router);
app.use(Toast);
app.use(CanvasJSChart);
app.mount('#app');
