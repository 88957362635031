<template>
    <BannerSection title="PRESIDENTS & SECRETARIES OF THE COLLEGE" />

    <div class="container">
        <h4>PRESIDENTS & SECRETARIES OF THE COLLEGE</h4><hr>
        <div class="text-center">
            <h5 class="text-primary">NATIONAL COLLEGE OF CHEST PHYSICIANS (INDIA)</h5>
            <p><b>GOVERNING COUNCIL     (YEAR – 2024-2025)</b></p>
            <p>W.E.F. - 1-4-2024</p>
        </div>

        <table>
            <tr>
                <th v-for="(heading, index) in  tableHeading" :key="index" class="tableHeading">{{ heading }}</th>
            </tr>

            <tr v-for="(data, index) in tableData" :key="index">
                <td>{{ data.year }}</td>
                <td>{{ data.president }}</td>
                <td>{{ data.secretaries }}</td>
            </tr>
        </table>
    </div>
</template>

<script>
import BannerSection from "@/components/BannerSection.vue";

export default{
    components: {
        BannerSection,
    },
    data(){
    return{
        tableHeading: ["Year", "President", "Secretaries" ],
        tableData: [
           {year: 2020, president:"Suraj Jha", secretaries: "Suraj"},
           {year: 2020, president:"Suraj Jha", secretaries: "Suraj"},
           {year: 2020, president:"Suraj Jha", secretaries: "Suraj"},
           {year: 2020, president:"Suraj Jha", secretaries: "Suraj"},
           {year: 2020, president:"Suraj Jha", secretaries: "Suraj"},
           {year: 2020, president:"Suraj Jha", secretaries: "Suraj"},
           {year: 2020, president:"Suraj Jha", secretaries: "Suraj"},
           {year: 2020, president:"Suraj Jha", secretaries: "Suraj"},
           {year: 2020, president:"Suraj Jha", secretaries: "Suraj"},
           {year: 2020, president:"Suraj Jha", secretaries: "Suraj"},
           {year: 2020, president:"Suraj Jha", secretaries: "Suraj"},
           {year: 2020, president:"Suraj Jha", secretaries: "Suraj"},
           {year: 2020, president:"Suraj Jha", secretaries: "Suraj"},
           {year: 2020, president:"Suraj Jha", secretaries: "Suraj"},
           {year: 2020, president:"Suraj Jha", secretaries: "Suraj"},
           {year: 2020, president:"Suraj Jha", secretaries: "Suraj"},
           {year: 2020, president:"Suraj Jha", secretaries: "Suraj"},
           {year: 2020, president:"Suraj Jha", secretaries: "Suraj"},
           {year: 2020, president:"Suraj Jha", secretaries: "Suraj"},
        ]
    }
    }
}
</script>

<style scoped>
.tableHeading{
   padding: 10px;
}
table {
    width: 50%;
    border-collapse: collapse;
}

th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}
tr:nth-child(odd){
    background-color: #fff7f7;
}
th {
    background-color: #06e;
    color:white;
}
</style>
